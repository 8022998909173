import { Component, JSX } from "solid-js";

export interface Props {
    children: JSX.Element;
}

export const ContentBox: Component<Props> = (props) => (
    <div style={{ "border": "1px solid #cdcdcd", "padding": "8px 5px 5px 10px", "margin": "10px 0 0 0", "background-color": "#ffffff", "box-shadow": "5px 5px 5px #333333", "-moz-box-shadow": "5px 5px 5px #333333", "-webkit-box-shadow": "5px 5px 5px #333333", "text-align": "center" }}>
        {props.children}
    </div>
)