import { Component, For } from "solid-js";
import VerificationNavigationButtons from "./verificationNavigationButtons";
import { ContentBox } from "./contentBox";
import { verificationConfirmationStore } from "../Stores/verificationsConfirmationStore";
import { verificationsRequests } from "../Stores/verificationsRequestingStore";

export interface SummaryProps {
    onNext: () => void;
    onBack: () => void;
    onCancel: () => void;
}

export const Summary: Component<SummaryProps> = (props) => {
    return (
        <div class="text-left">
            <h1 class='text-4xl'>Verification Review</h1>
            <ContentBox>
                <div class="text-left my-2">Verification of your licensure status with the following state licensure board{(verificationsRequests?.verificationCount ?? 0) > 1 ? 's' : ''}:</div>
                <ul class="text-left list-disc list-inside ml-4 font-bold">
                    <For each={verificationConfirmationStore.fromBoards}>
                        {(board) => <li>{board.name}</li>}
                    </For>
                </ul>

                <div class="text-left mt-6 mb-4">Will be sent to:</div>
                <ul class="text-left list-disc list-inside ml-4 font-bold">
                    <For each={verificationConfirmationStore.toBoards}>
                        {(board) => <li>{board.name}</li>}
                    </For>
                </ul>
                <div class="text-left my-4">Transaction amount: <strong>${verificationConfirmationStore.totalCost.toFixed(2)}</strong></div>
                <div class="text-left mb-4 mt-8">
                    If this is correct, please click 'Continue' to enter payment information and send verification{(verificationsRequests?.verificationCount ?? 0) > 1 ? 's' : ''}.
                </div>
                <div class="text-left font-bold">Please review carefully.   ALL TRANSACTIONS ARE FINAL.</div>
                <VerificationNavigationButtons onNext={props.onNext} onBack={props.onBack} onCancel={props.onCancel} />
            </ContentBox>
        </div>
    )
}