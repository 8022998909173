import { createStore } from 'solid-js/store';

export interface SearchRequestStore {
    lastName: string[];
    dateOfBirth: string;
    licenseTypeGroupId: string;
}

const base = {
    lastName: [''],
    dateOfBirth: '',
    licenseTypeGroupId: 'a1b2c3d4-e5f6-47a8-89b0-1c2d3e4f5a6b'
};

const [searchRequestStore, setSearchRequestStore] = createStore<SearchRequestStore>({ ...base });
export const resetSearchRequestStore = () => setSearchRequestStore({ ...base });

export { searchRequestStore, setSearchRequestStore };