import { Component } from "solid-js";

export interface Props {
    message: string;
}

export const Error: Component<Props> = (props) => (
    <div class="rounded-md bg-red-100 border  text-red-700 p-4">
        <div class="flex">
            <div class="flex-shrink-0">
                <svg class="h-7 w-7 text-red-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clip-rule="evenodd" />
                </svg>
            </div>
            <div class="ml-3 text-left">
                {/* <strong class="font-bold">Error: </strong> */}
                <span class="block sm:inline">{props.message}</span>
            </div>
            {/* <div class="ml-3">
                <h3 class="font-medium text-red-800">There were errors with your submission</h3>
                <div class="mt-2 text-red-700">
                    <ul role="list" class="list-disc space-y-1 pl-5 text-left">
                        <li>{props.message}</li>
                    </ul>
                </div>
            </div> */}
        </div>
    </div>
)

export default Error;