import { createResource, type Component, For, Show, createSignal, Match, Switch } from "solid-js";
import { A } from "@solidjs/router";
import { FaqEntity, FaqRouteService } from "../ApiRequests";
import { Faq } from "../Components/faq";
import { ContentBox } from "../Components/contentBox";

// This method filters and sorts the FAQ list based on the searchText and looks for partial matches
const processFaqs = (searchText: string, faqs: FaqEntity[]) => {
    // Create a new reactive state for the processed FAQs
    const processedFaqs = [];

    // Split the searchText into individual words
    const searchWords = searchText.toLowerCase().split(" ");

    faqs.forEach((faq, i) => {
        const destinctFaqText = Array.from(new Set((faq.question + " " + faq.answer).toLowerCase().split(" ")));

        // Calculate a match score for each entry based on how many words in the searchText are found in the question and answer
        const matchScore = searchWords.reduce((score, word) => {
            if (destinctFaqText.some(x => x.includes(word))) {
                return score + 1;
            } else {
                return score;
            }
        }, 0);

        // If the match score is greater than 0, add the entry to the processedFaqs array with its match score
        if (matchScore > 0) {
            processedFaqs.push({ index: i, matchScore });
        }
    })

    // Sort the processedFaqs array in descending order by match score

    processedFaqs.sort((a, b) => b.matchScore - a.matchScore);

    // Return the processedFaqs array
    return processedFaqs.map(faq => faqs[faq.index]);
};

// This method filters the FAQ list based on the searchText without sorting and looks for exact matches
const simpleProcessFaqs = (searchText: string, faqs: FaqEntity[]) => {
    return faqs.filter((f) => searchText == '' || f.question.toLowerCase().includes(searchText.toLowerCase()) || f.answer.toLowerCase().includes(searchText.toLowerCase()))
}


export const FaqPage: Component = () => {

    const [faqs] = createResource(() => FaqRouteService.getApiV1Faq());
    const [searchText, setSearchText] = createSignal("");

    return (
        <div class="text-left">
            <h1 class="text-4xl">Frequently Asked Questions</h1>
            <ContentBox>
                <Switch>
                    <Match when={faqs.state === 'pending' || faqs.state === 'refreshing'}>
                        <div>Loading...</div>
                    </Match>
                    <Match when={faqs.state === 'errored'}>
                        <div>An error occurred fetching the FAQs. Please refresh.</div>
                    </Match>
                    <Match when={faqs.state === 'ready'}>
                        <div class="text-left">
                            <div class="flex">
                                <label for="search-dropdown" class="mb-2 text-2xl font-medium text-gray-900 sr-only dark:text-white">Your Email</label>
                                <div class="relative w-full">
                                    <input type="search" id="search-dropdown"
                                        class="
                                            block
                                            p-2.5
                                            w-full
                                            z-20
                                            text-2xl
                                            text-gray-900
                                            bg-gray-50
                                            rounded-lg
                                            border-2
                                            border-gray-300
                                            outline-none
                                            focus:ring-gray-500
                                            focus:border-gray-500
                                            dark:placeholder-gray-400"
                                        placeholder="Search FAQs..."
                                        onChange={(e) => setSearchText(e.currentTarget.value)}
                                        onKeyUp={(e) => { console.log("press" + e.currentTarget.value); setSearchText(e.currentTarget.value) }} />
                                </div>
                            </div>
                            <For each={processFaqs(searchText(), faqs())} fallback={<p class="">Sorry, no matches found.</p>}>
                                {(faq) => (<Faq question={faq.question} answer={faq.answer} />)}
                            </For>
                        </div>
                    </Match>
                </Switch>
            </ContentBox>
        </div>
    )
}
export default FaqPage;