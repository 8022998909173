import { resetAvailableSites } from "../Stores/availableSitesStore";
import { resetResultsSelected } from "../Stores/resultsSelectedStore";
import { resetSearchRequestStore } from "../Stores/searchRequestStore";
import { resetSearchResults } from "../Stores/searchResultsStore";
import { resetVerificationConfirmationStore } from "../Stores/verificationsConfirmationStore";
import { resetVerificationRequests } from "../Stores/verificationsRequestingStore";

export const resetPaymentStores = () => {
    resetSearchResults();
    resetAvailableSites();
    resetSearchRequestStore();
    resetVerificationRequests();
    resetResultsSelected();
    resetVerificationConfirmationStore();
}
