import {
  Component,
  For,
  Show,
  createEffect,
  createResource,
  createSignal,
  onMount,
} from "solid-js";
import { setSearchResults } from "../Stores/searchResultsStore";
import {
  ApiError,
  LicenseRouteService,
  LicenseSearchRequest,
  LicenseSearchResults,
  LicenseTypeRouteService,
  MasterLicenseTypeResults,
} from "../ApiRequests";
import VerificationNavigationButtons from "./verificationNavigationButtons";
import {
  searchRequestStore,
  setSearchRequestStore,
} from "../Stores/searchRequestStore";
import { ContentBox } from "./contentBox";
import Info from "./info";
import * as Sentry from "@sentry/browser";

export interface SearchFormProps {
  onNext: () => void;
  onCancel: () => void;
  licenseTypeGroups: MasterLicenseTypeResults[];
}

//TODO: Import setSearchRequestStore & swap signals to use it instead.

const SearchForm: Component<SearchFormProps> = (props) => {
  let form: HTMLFormElement;

  const [searchProcessingMessage, setSearchProcessingMessage] =
    createSignal("");
  const [errorMessage, setErrorMessage] = createSignal("");
  // const [nameForms, setNameForms] = createSignal([1]);

  const handleAddLastName = () => {
    updateLastNames();
    setSearchRequestStore({ lastName: [...searchRequestStore.lastName, ""] });
    setTimeout(() => {
      //need to wait for the new input to be added to the DOM
      const nameInputs = form.querySelectorAll(".js-last-name");
      (nameInputs[nameInputs.length - 1] as HTMLInputElement).focus();
    }, 100); // 100 ms is arbitrary, but seems to work well in keeping tab order
  };

  const handleRemoveLastName = (index: number) => {
    updateLastNames();
    setSearchRequestStore({
      lastName: searchRequestStore.lastName.filter((_, i) => i !== index),
    });
    setTimeout(() => {
      const nameInputs = form.querySelectorAll(".js-last-name");
      (nameInputs[nameInputs.length - 1] as HTMLInputElement).focus();
    }, 100); // 100 ms is arbitrary, but seems to work well in keeping tab order
  };

  /**
   * Update store with last name input value. Uses two if statements & a setTimeout to keep tab order consistent. After store is set, focus moves to body. If body is still focused after 100ms, focus moves to the next button. We do a second check after the timeout to ensure that focus is still on the body & not on a button or input. Focus will always be on the body element when the focus is moved by SolidJS.
   * @param index index of the last name input
   * @param value value of the last name input
   */
  const handleLastNameChange = (index: number, value: string) => {
    const nameInputs = form.querySelectorAll(".js-last-name");
    setSearchRequestStore({
      lastName: [
        ...Array.from(nameInputs).map((x) => (x as HTMLInputElement).value),
      ],
    });
    if (document.querySelector("body") == document.activeElement) {
      setTimeout(() => {
        if (document.querySelector("body") == document.activeElement) {
          const input = form.querySelectorAll(".js-last-name")[index];
          (input as HTMLInputElement).parentElement
            .querySelector("button")
            .focus();
        }
      }, 100); // 100 ms is arbitrary, but seems to work well in keeping tab order
    }
  };

  const updateLastNames = () => {
    const nameInputs = form.querySelectorAll(".js-last-name");
    setSearchRequestStore({
      lastName: [
        ...Array.from(nameInputs).map((x) => (x as HTMLInputElement).value),
      ],
    });
  };

  onMount(() => {
    form.querySelector("input").focus();
  });

  // TODO: save data  for when user goes back
  const handleSubmit = async (e: Event) => {
    e.preventDefault();
    const token = await grecaptcha.execute(
      "6Lc4dKsaAAAAAC19W1N354oeg7CDX7WIusazgdRc",
      { action: "submit" }
    );

    updateLastNames();
    if (
      searchRequestStore.lastName.length < 1 ||
      searchRequestStore.lastName.join("").trim() === ""
    ) {
      setErrorMessage("Please enter a last name");
      return;
    }
    if (
      searchRequestStore.dateOfBirth !== null &&
      searchRequestStore.dateOfBirth.trim() === ""
    ) {
      setErrorMessage("Please enter a valid date of birth");
      return;
    }
    if (
      searchRequestStore.licenseTypeGroupId !== null &&
      searchRequestStore.licenseTypeGroupId.trim() === ""
    ) {
      setErrorMessage("Please select a license type");
      return;
    }
    try {
      setSearchProcessingMessage(
        `Searching for ${searchRequestStore.lastName}...`
      );
      setSearchRequestStore({
        lastName: searchRequestStore.lastName
          .map((x) => x.trim())
          .filter((x) => x !== ""),
      });
      const requestBody: LicenseSearchRequest = {
        lastName: searchRequestStore.lastName,
        dateOfBirth: searchRequestStore.dateOfBirth,
        licenseTypeGroupId: searchRequestStore.licenseTypeGroupId,
        captchaToken: token,
      };
      const licenseSearchResponse =
        await LicenseRouteService.postApiV1LicensesSearch({ requestBody });
      setSearchResults(licenseSearchResponse);
      props.onNext();
    } catch (error) {
      setErrorMessage(
        "An unexpected error occurred while searching for licenses. We are looking into it."
      );
      setSearchProcessingMessage("");
      if (error instanceof ApiError) {
        const errorBodyStr = error.body ? error.body.toString() : "";

        if (error.status === 400 && errorBodyStr.includes("CAPTCHA")) {
          setErrorMessage(
            "We are unable to process your request at this time as our Captcha system cannot authenticate your request. Please try again on another device."
          );
          Sentry.captureEvent({
            message: "Captcha error",
            extra: {
              body: errorBodyStr,
              status: error.status,
              url: error.url,
              request: error.request,
              errorMessage: errorMessage(),
            },
          });
        } else if (error.status === 404) {
          setErrorMessage("No license information found. " + errorBodyStr);
        } else if (error.status === 429) {
          setErrorMessage("Locked out.");
        } else {
          Sentry.captureException(error, {
            extra: {
              body: errorBodyStr,
              status: error.status,
              url: error.url,
              request: error.request,
              errorMessage: errorMessage(),
            },
          });
        }
      } else {
        // Capture other types of errors, including unhandled promise rejections
        Sentry.captureException(error, {
          extra: { errorMessage: errorMessage() },
        });
      }
    }
  };

  const goBack = () => {
    updateLastNames();
    props.onCancel();
  };

  return (
    <div>
      <h1 class="text-4xl">Find License Information</h1>
      {/* TODO: Update health care licensing boards to be an api request for count & a link */}
      <p>
        The Information entered below will be used to locate medical licensure
        information within the VeriDoc system for physicians, physician
        assistants and some allied health professionals who have or have had
        licenses issued from any one the{" "}
        <a href="/RegisteredStates" class="inactive">
          30 health care licensing boards
        </a>{" "}
        that contract with VeriDoc for the transmittal of its license
        verifications.
      </p>
      <ContentBox>
        <form onSubmit={handleSubmit} ref={form} class="text-left">
          <div>
            <label class="font-bold">Last Name</label>
            <For each={searchRequestStore.lastName}>
              {(lastName, index) => (
                <div class="">
                  <input
                    title="Last Name"
                    class="js-last-name bg-white border-solid border-2 border-gray-300 rounded mb-1"
                    value={lastName}
                    // onChange={(e) => handleLastNameChange(index(), e.target.value)}
                  />
                  <Show
                    when={
                      searchRequestStore.lastName.length > 1 &&
                      index() < searchRequestStore.lastName.length - 1
                    }
                  >
                    <button
                      class="bg-red-800 border-red-900 hover:bg-red-900 border-2 ml-4 px-2 rounded-md text-white text-2xl  w-12"
                      title="Remove Last Name Variant"
                      type="button"
                      tabIndex={0}
                      // onMouseDown={() => handleRemoveLastName(index())}
                      onClick={() => handleRemoveLastName(index())}
                      // onClick={(e) => e.type !== 'mousedown' && handleRemoveLastName(index())}
                    >
                      {" "}
                      -
                    </button>
                  </Show>
                  <Show
                    when={index() === searchRequestStore.lastName.length - 1}
                  >
                    <button
                      class={`${
                        searchRequestStore.lastName.length > 5
                          ? "bg-gray-300 border-gray-400 hover:bg-gray-400"
                          : "bg-sky-700 border-sky-800 hover:bg-sky-800"
                      } border-2 ml-4 px-3 rounded-md text-white text-2xl w-12`}
                      title="Add Last Name Variant"
                      type="button"
                      // onClick={(e) => e.type !== 'mousedown' && handleAddLastName()}
                      // onMouseDown={() => handleAddLastName()}
                      onClick={() => handleAddLastName()}
                      tabIndex={0}
                      disabled={searchRequestStore.lastName.length > 5}
                    >
                      +
                    </button>
                  </Show>
                </div>
              )}
            </For>
          </div>
          <small>
            Must enter exactly as it appears in the state licensure board's
            database. (not case senstive)
          </small>

          <div>
            <label class="block mt-4" for="date-of-birth">
              Date of Birth
            </label>
            <input
              title="Date of Birth"
              type="date"
              id="date-of-birth"
              required={true}
              value={searchRequestStore.dateOfBirth}
              onChange={(e) =>
                setSearchRequestStore({ dateOfBirth: e.target.value })
              }
              class="bg-white border-solid border-2 border-gray-300 rounded"
            />
          </div>
          <div>
            <label class="block mt-4" for="license-type">
              Licensure Type
            </label>
            <select
              title="License Type"
              id="license-type"
              class="bg-white border-solid border-2 border-gray-300 rounded"
              onChange={(e) =>
                setSearchRequestStore({ licenseTypeGroupId: e.target.value })
              }
            >
              <For
                each={props.licenseTypeGroups
                  .map((g) => ({ groupId: g.groupId, name: g.group }))
                  .filter(
                    (v, i, a) =>
                      a.findIndex((t) => t.groupId === v.groupId) === i
                  )
                  .sort((a, b) => {
                    if (a.name.startsWith("Physician")) return -1;
                    if (b.name.startsWith("Physician")) return 1;
                    return a.name.localeCompare(b.name);
                  })}
              >
                {(licenseType) => (
                  <option value={licenseType.groupId}>
                    {licenseType.name}
                  </option>
                )}
              </For>
            </select>
          </div>
          <Show when={errorMessage()}>
            <div
              class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-4"
              role="alert"
            >
              <strong class="font-bold pr-2">Error: </strong>
              <span class="block sm:inline"> {errorMessage()}</span>
            </div>
          </Show>
          <Show when={searchProcessingMessage()}>
            <Info message={searchProcessingMessage()} />
          </Show>

          <VerificationNavigationButtons
            onSubmit={handleSubmit}
            onCancel={goBack}
          />
        </form>
      </ContentBox>
    </div>
  );
};

export default SearchForm;
