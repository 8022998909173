import type { Component } from "solid-js";
import { A, useParams } from "@solidjs/router";
import { Verification } from "./Verification";

export interface Props {
    viewState?: number;
}

export const HomePage: Component<Props> = (props) => {
    return (
        <Verification viewState={props.viewState} />
    )
}

export default HomePage;