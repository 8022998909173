import { Component, For, Match, Resource, Show, Switch, createEffect, createResource, createSignal, onMount } from "solid-js"
import { ContentBox } from "../Components/contentBox"
import { useParams, useRouteData } from "@solidjs/router";
import { TransactionResponse, TransactionRouteService } from "../ApiRequests";
import Info from "../Components/info";
import Error from "../Components/error";
import { Portal } from "solid-js/web";

export const TransactionComplete: Component = () => {
    const params = useParams();
    const [transaction, { refetch }] = createResource(params.id, (id) => TransactionRouteService.getApiV1Transactions1({ publicTransactionId: id }));
    const [clickedNotReadyFile, setClickedNotReadyFile] = createSignal(-1);

    // create an effect that checks if transaction has any verifications with verificationCopyCreated === true when transaction is updated
    createEffect(() => {
        if (transaction.loading === false && transaction().verifications.length > 0 && transaction().verifications[0].verificationCopyCreated === true) {
            if (clickedNotReadyFile() >= 0) {
                const index = clickedNotReadyFile();
                document.querySelector('.download-files').querySelectorAll('a')[index].click();
                setClickedNotReadyFile(-1);
            }
        }
    })

    onMount(async () => {
        // If verification PDFs haven't been generated check again in 30 seconds
        function refreshInterval() {
            setTimeout(() => {
                if (transaction.loading === false && transaction().verifications.length > 0 && transaction().verifications[0].verificationCopyCreated === false) {
                    refetch();
                    refreshInterval();
                }
            }, 20_000);
        }
        refreshInterval();
    })

    return (
        <div class="text-left">
            <h1 class="text-4xl">Transaction Complete</h1>
            <ContentBox>

                <Switch fallback={<div />}>

                    <Match when={transaction.error}>
                        <Error message="Could not find transaction." />
                    </Match>
                    <Match when={transaction.loading}>
                        <Info message="Getting Transaction..." />
                    </Match>

                    <Match when={transaction.loading == false}>
                        <div class="font-bold mb-4 mt-4 text-left">Your credit card has been approved and your verification has been sent.</div>
                        <div class="text-left italic">A receipt for this transaction has been emailed to <strong>{transaction().email}</strong></div>

                        <div class="border-dashed border-gray-300 border-2 px-4 py-6 mt-4">
                            <div class="text-left mb-4"><strong>Transaction Date:</strong> {transaction().date}</div>
                            <div class="text-left mb-4"><strong>Physician Name:</strong> {transaction().name}</div>
                            <div class="text-left mb-4"><strong>Amount Charged:</strong> ${transaction().amount}</div>
                            <div class="text-left mb-4"><strong>Confirmation #:</strong> {transaction().confirmationNumber}</div>
                            <Show when={transaction().verifications[0].verificationCopyCreated === false && clickedNotReadyFile() >= 0}>
                                <Portal>
                                    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                                        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" onClick={() => setClickedNotReadyFile(-1)} />

                                        <div class="fixed inset-0 z-10 overflow-y-auto">
                                            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                                <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg text-2xl">

                                                    <button type="button" class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" onClick={() => setClickedNotReadyFile(-1)}>
                                                        <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" /></svg>
                                                        <span class="sr-only">Close modal</span>
                                                    </button>
                                                    <div class="p-6 text-center">
                                                        <div class="text-center mt-2 block">
                                                            <svg class="animate-spin h-10 w-10 mx-auto text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" />
                                                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                                                            </svg>
                                                        </div>
                                                        <p class="mt-5 mb-2 font-bold text-gray-800 dark:text-gray-200">Please wait, your copy is generating.</p>
                                                        <p class="mb-10 text-gray-600 dark:text-gray-200">This will close automatically upon finishing. </p>
                                                        <div class="block">
                                                            <button type="button" class="py-3 px-5 mr-5 rounded-md bg-gray-200 hover:bg-gray-300 text-black" onClick={() => setClickedNotReadyFile(-1)}>Close</button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Portal>
                            </Show>
                            <div class="text-left ml-12 mt-12">
                                <div class="">
                                    <Show when={transaction().verifications.length == 1}>
                                        Verification of licensure status with the following state board:
                                    </Show>
                                    <Show when={transaction().verifications.length > 1}>
                                        Verification of licensure status with the following state boards:
                                    </Show>
                                </div>
                                <Show when={transaction().verifications[0].verificationCopyCreated === false}>
                                    <ul class="text-left list-disc list-inside ml-8 mb-12 mt-6 font-bold">
                                        <For each={Array.from(new Set(transaction().verifications.map(v => v.licenseStateBoardName)))}>
                                            {(name, i) => (<li class="cursor-pointer" onClick={() => setClickedNotReadyFile(i())}>{name}</li>)}
                                        </For>
                                    </ul>
                                </Show>
                                <Show when={transaction().verifications[0].verificationCopyCreated === true}>
                                    <ul class="text-left list-disc list-inside ml-8 mb-12 mt-6 font-bold download-files">
                                        <For each={Array.from(new Set(transaction().verifications.map(v => v.licenseStateBoardName)))}>
                                            {(name) =>
                                            (<li>
                                                <a href={`/verifications/${transaction().verifications.filter(v => v.licenseStateBoardName === name)[0].fileName}`} target="_blank">
                                                    {name}
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 inline text-xl text-red-600 ml-3 mb-1">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                                    </svg>
                                                </a>
                                            </li>)
                                            }
                                        </For>
                                    </ul>
                                </Show>
                                Have been sent to:
                                <ul class="text-left list-disc list-inside ml-8 mt-6 font-bold">
                                    <For each={Array.from(new Set(transaction().verifications.map(v => v.destinationStateBoardName)))}>
                                        {(name) => <li>{name}</li>}
                                    </For>
                                </ul>
                            </div>

                        </div>

                        <div class="mt-24 mb-12 text-center font-bold">
                            THANK YOU FOR USING THE VeriDoc SYSTEM.

                            <div class="mt-4"> - All Transactions Are Final - </div>
                        </div>
                    </Match>
                </Switch>

            </ContentBox>
        </div>
    )
}

export default TransactionComplete;