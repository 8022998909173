import {
  createResource,
  type Component,
  For,
  Match,
  Switch,
  createEffect,
} from "solid-js";
import { LicenseTypeRouteService } from "../ApiRequests";
import { ContentBox } from "../Components/contentBox";

export const AcceptingStatesPage: Component = () => {
  const [states] = createResource(() =>
    LicenseTypeRouteService.getApiV1LicenseTypesBoardsAcceptingVerifications()
  );

  createEffect(() => {
    // Hack for now to update page title
    document.querySelector("#ctl00_lblPageTitle").textContent =
      "License Search";
  });

  return (
    <div class="text-left">
      <h1 class="text-4xl">
        State Licensure Boards who can Receive Verifications
      </h1>
      <p>
        The state licensure boards listed below have agreed to accept
        verifications processed through VeriDoc and sent to the board
        electronically. Verifications processed through VeriDoc are not the same
        as a 'web verification'.
      </p>
      <ContentBox>
        <Switch>
          <Match
            when={states.state === "pending" || states.state === "refreshing"}
          >
            <div>Loading...</div>
          </Match>
          <Match when={states.state === "errored"}>
            <div>An error occurred fetching the states. Please refresh.</div>
          </Match>
          <Match when={states.state === "ready"}>
            <div class="text-left">
              <ol>
                <For
                  each={states()}
                  fallback={<p class="">Sorry, no matches found.</p>}
                >
                  {(state) => <li>{state}</li>}
                </For>
              </ol>
            </div>
          </Match>
        </Switch>
      </ContentBox>
    </div>
  );
};
export default AcceptingStatesPage;
