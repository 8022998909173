import { Component } from "solid-js";

export interface Props {
    message: string;
}

export const Info: Component<Props> = (props) => (
    <div class="rounded-md bg-blue-500 border  p-4">
        <div class="flex">
            <div class="flex-shrink-0">
                <svg class="h-7 w-7 text-blue-100 " viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z" clip-rule="evenodd" />
                </svg>
            </div>
            <div class="ml-3 flex-1 md:flex md:justify-between" role="alert">
                <span class="text-white">{props.message}</span>
            </div>
        </div>
    </div>
)

export default Info;