import { Component, Show } from "solid-js";

export interface Props {
    onNext?: () => void;
    onSubmit?: (e: MouseEvent) => void;
    onSubmitDisabled?: boolean;
    onBack?: () => void;
    onCancel?: () => void;
}

export const VerificationNavigationButtons: Component<Props> = (props) => {
    return (
        <div class="my-10">
            <Show when={props.onNext}><button type="button" class="py-3 px-5 mr-5 rounded-md bg-sky-800 hover:bg-sky-900 text-white" onClick={() => props.onNext()}>Continue</button></Show>
            <Show when={props.onSubmit}><button type="submit" class={`py-3 px-5 mr-5 rounded-md  text-white ${props.onSubmitDisabled ? 'bg-gray-300 hover:bg-gray-300 hover:cursor-not-allowed' : 'bg-sky-800 hover:bg-sky-900'}`} onClick={(e) => props.onSubmit(e)} disabled={props.onSubmitDisabled}>Continue</button></Show>

            <Show when={props.onBack}>
                <button type="button"
                    // Per JoDee - Wants back button blue when submit is disabled & no next button
                    // TODO: Create a test for this scenario
                    class={`py-3 px-5 mr-5 rounded-md ${props.onSubmitDisabled && props.onNext == undefined ? 'bg-sky-800 hover:bg-sky-900 text-white' : 'bg-gray-300 hover:bg-gray-300 text-black '}`}
                    onClick={() => props.onBack()}>
                    Back
                </button>
            </Show>

            <Show when={props.onCancel}><button type="button" class="py-3 px-5 mr-5 rounded-md bg-gray-200 hover:bg-gray-300 text-black" onClick={() => props.onCancel()}>Cancel</button></Show>
        </div>
    )
}

export default VerificationNavigationButtons;