import { A } from "@solidjs/router";
import {
  For,
  Match,
  Switch,
  createEffect,
  createResource,
  type Component,
} from "solid-js";
import { LicenseTypeRouteService } from "../ApiRequests";
import { ContentBox } from "../Components/contentBox";

createEffect(() => {
  // Hack for now to update page title
  document.querySelector("#ctl00_lblPageTitle").textContent = "License Search";
});

export const RegisteredStatesPage: Component = () => {
  const [group] = createResource(() =>
    LicenseTypeRouteService.getApiV1LicenseTypesBoardsProvidingVerifications()
  );

  return (
    <div class="text-left">
      <h1 class="text-4xl">State Licensure Boards that Utilize VeriDoc</h1>
      <p>
        The state licensure boards listed below use VeriDoc to process their
        state to state written license verifications. Licenses that have been
        issued by the state boards listed below may be verified through VeriDoc
        to ANY other state medical or osteopathic board located in the United
        States. For a complete listing of the state boards that will accept
        receipt of verifications processed through VeriDoc,{" "}
        <A href="/acceptingstates">click here</A>.
      </p>
      <ContentBox>
        <Switch>
          <Match
            when={group.state === "pending" || group.state === "refreshing"}
          >
            <div>Loading...</div>
          </Match>
          <Match when={group.state === "errored"}>
            <div>An error occurred fetching the licenses. Please refresh.</div>
          </Match>
          <Match when={group.state === "ready"}>
            <div class="text-left">
              <nav class=" overflow-hidden inline-block indent-3">
                <For
                  each={group().filter(
                    (group) =>
                      group.length > 0 &&
                      group[0].licenseType !== "Resident Physician"
                  )}
                >
                  {(group) => (
                    <a
                      href={`#${group[0].licenseType.replaceAll(" ", "")}`}
                      class="pr-4 border-l border-l-gray-500 first:border-none first:pl-1 -ml-px inline-block my-2"
                    >
                      {group[0].licenseType}
                    </a>
                  )}
                </For>
              </nav>
              <ol>
                <For
                  each={group().filter(
                    (group) =>
                      group.length > 0 &&
                      group[0].licenseType !== "Resident Physician"
                  )}
                  fallback={<p class="">Sorry, no matches found.</p>}
                >
                  {(group) => (
                    <li>
                      <ol style={{ "margin-left": 0 }}>
                        <strong id={group[0].licenseType.replaceAll(" ", "")}>
                          {group[0].licenseType}
                        </strong>
                        <For each={group}>
                          {(board) => (
                            <li style={{ "margin-left": "16px" }}>
                              {board.description}
                            </li>
                          )}
                        </For>
                      </ol>
                    </li>
                  )}
                </For>
              </ol>
            </div>
          </Match>
        </Switch>
      </ContentBox>
    </div>
  );
};
export default RegisteredStatesPage;
