import { Component } from "solid-js";

export interface FaqProps {
    question: string;
    answer: string;
}

export const Faq: Component<FaqProps> = (props) => (
    <div>
        <p class="font-bold">Q. {props.question}</p>
        <div class="pl-8 my-4" innerHTML={`A. ${props.answer}`} />
    </div>
)