import { A } from "@solidjs/router";
import { Component, Show, onMount } from "solid-js";
import { ContentBox } from "./contentBox";

export type CreateAndSendVerificationProps = {
    onNext: () => void;
    showButton?: boolean;
};

const CreateAndSendVerification: Component<CreateAndSendVerificationProps> = (props) => {

    const handleClick = () => {
        props.onNext();
    };

    return (
        <>
            <ContentBox>
                <div class="pb-8">
                    <div style={{ "font-family": "Times New Roman", "font-size": "18px" }}>
                        <p>
                            This site is intended for use by physicians, physician assistants and some allied health professionals, who practice in the United States and need to have verification of the status of their active and inactive medical licenses sent to another medical licensing board.
                        </p>

                        <p class="italic text-2xl leading-10">
                            This site cannot be used by medical facilities and/or employers<br />
                            for credentialing physicians.
                        </p>
                    </div>
                    <div class="my-8 text-md">
                        <A href="/overview">
                            Read the overview.
                        </A>
                    </div>
                    <Show when={props.showButton}>
                        <button class="btn bg-sky-700 text-xl rounded-2xl px-6 pt-4 pb-10 text-white hover:bg-sky-900 hover:text-white focus:text-white" onClick={handleClick} autofocus>Create & Send Verification</button>
                    </Show>
                </div>
            </ContentBox>
        </>
    );
}

export default CreateAndSendVerification;