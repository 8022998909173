import type { Component } from "solid-js";
import { A } from "@solidjs/router";

export const OverviewPage: Component = () => (
    <div class="text-left">
        <p>
            The purpose of this site is to expedite the process of verifying the status of a medical license. It is intended for use by medical health professionals who hold one or more medical licenses in the United States and need to have verification of their licensure status sent to another licensing board.
        </p>
        <p>
            A licensure verification can be sent to verify that status of a license issued by any of the <A href="/registeredstates">state licensure boards that utilize VeriDoc</A>.  If you hold or have held a license issued by a board that is not included in our participating boards, you will need to contact that licensing board directly with your request. A verification can be sent through VeriDoc to a licensing board in the United States and the Colleges of Physician and Surgeons of Nova Scotia and Ontario.  VeriDoc cannot process verifications to any other international destinations.
        </p>
        <p>
            Sending verifications from this site is a simple five step process.
        </p>
        <p>
            <strong>Step 1:</strong><span style={{ "font-style": "italic" }}> Enter identifying information.</span>&nbsp; You will be asked to enter identifying information about yourself which will then be used to locate your license information by searching through all licenses issued by any of the <A href="/registeredstates">state licensure boards that utilize VeriDoc</A>.
        </p>
        <p>
            <strong>Step 2:</strong><span style={{ "font-style": "italic" }}> Select licenses to include in transaction.</span>&nbsp; Information for each license found will be displayed. Here you will determine which licenses you wish to include in your verification statement(s).
        </p>
        <p>
            <span style={{ "font-style": "italic" }}>Note:</span> Most state boards generally require verification of every medical license you have ever&nbsp;held whether or not the license is active at this time.
        </p>
        <p>
            <strong>Step 3:</strong><span style={{ "font-style": "italic" }}> Select one or more state licensing boards to receive the verifications.</span>&nbsp; You will be asked to select the state board to whom the verification statement(s) should be sent.
        </p>
        <p>
            <strong>Step 4:</strong><span style={{ "font-style": "italic" }}> Review information.</span>&nbsp; A summary of the verification statement(s) that will be sent is displayed, as well as the fee to be charged.  Different <a href="/feeschedule.aspx">fees</a> may be charged by each state board.   Here you will have a chance to review the verifying board(s) and the receiving board(s) to make sure they are correct before completing the transaction.
        </p>
        <p>
            <strong>Step 5:</strong><span style={{ "font-style": "italic" }}> Enter payment information and send verifications.</span>&nbsp; Here you will be required to enter the credit card information and submit the verification statement or statements for transmittal. Upon approval of the credit card, a confirmation page will be generated for you to view and print. A receipt of transaction will also be emailed to the email address provided by you.
        </p>
        <p>
            The whole process of sending verifications takes only about 5-10 minutes.
            <A href="/search" >Create and send a verification now »</A>
        </p>
    </div>
)

export default OverviewPage;